.draggableItem {
  padding: 10px;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: grab;
}

.draggableItem:hover {
  background-color: #e6e6e6;
}

.draggingOver {
  background-color: #f0f0f0;
}

.rawHtmlContent {
  word-wrap: break-word; /* Breaks long words to prevent horizontal scrolling */
  overflow-wrap: break-word; /* Ensures words break to prevent overflow */
  white-space: pre-wrap; /* Maintains formatting but allows natural breaks */
}

.selectedChapter {
    background-color: #e6f7ff; /* Light blue background */
    border-left: 3px solid #1890ff; /* Blue left border */
}
