

/* Tablet - Medium screens */
@media only screen and (max-width: 992px) {
  .responsive-gif {
    width: 350px; /* Adjust this value as needed */
    height: auto;
  }
}

/* Mobile - Small screens */
@media only screen and (max-width: 768px) {
  .responsive-gif {
    width: 200px; /* Adjust this value as needed */
    height: auto;
  }
}
